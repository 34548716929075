import * as React from "react";
import { AlertColor, Box, CardActions, Grid, Typography } from "@mui/material";
import CclInfoTable from "../../../../components/common/cclInfoTable/cclInfoTable";
import ComponentLoader from "../../../../components/common/componentLoader";
import {
    useGetCclNotificationQuery,
    useResendNotificationMutation,
    useSendNotificationLowersMutation,
} from "../../../../services/cclTokenedNotificationApi";
import { AccessEventNotificationDetails } from "../../../../services/types/accessEventTypes";
import { CclInfoTblNotificationTemplate } from "../CclInfoTblNotificationTemplate";
import ReadOnlyGate from "../../../../components/userAccess/ReadOnlyGate";
import useLogAccessEvent from "../../../../hooks/useLogAccessEvent";
import { FeatureFlag } from "../../../../featureFlags";
import NotificationPreviewPanel from "../preview/NotificationPreviewPanel";
import CclGenericAccordian from "../../../../components/common/cclGenericAccordian";
import {
    UserClaimsService,
    eGroupIds,
} from "../../../../services/currentUserService/currentUserService";
import CclAlertSnackbar from "../../../../components/common/cclAlertSnackbar";
import CclLoadingButton from "../../../../components/common/cclButtons/cclLoadingButton";

interface NotificationGeneralPanelProps {
    notificationId: string;
}

const NotificationGeneralPanel: React.FC<NotificationGeneralPanelProps> = (props) => {
    const { data, isLoading, isSuccess } = useGetCclNotificationQuery(props.notificationId, {
        skip: !props.notificationId,
    });
    const [showSnackbar, setShowSnackbar] = React.useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor | undefined>("info");
    const [sendInProgress, setSendInProgress] = React.useState<boolean>(false);

    const [reSendNotification] = useResendNotificationMutation();
    const [sendNotification] = useSendNotificationLowersMutation();
    const { logEvent } = useLogAccessEvent();
    const features = React.useContext(FeatureFlag);
    const currentUserService = new UserClaimsService();
    const isUserClientAdmin = currentUserService.IsUserInGroups([eGroupIds.eClientAdmin]);

    const ResendNotification = () => {
        setSendInProgress(true);
        if (features?.resend?.active) {
            console.log("Sent Notification!");
            sendNotification(data?.notificationId!)
                .unwrap()
                .then(() => {
                    let rcpnt: string = "Unknown";
                    if (data?.recipients && data?.recipients.length > 0) {
                        rcpnt =
                            data.recipients.length > 1
                                ? data.recipients.map((r) => r.identifier).join(", ")
                                : data.recipients[0].identifier;
                    }
                    const evtData: AccessEventNotificationDetails = {
                        notificationId: data?.notificationId ?? "",
                        subject: data?.subject ?? "",
                        recipient: rcpnt,
                    };

                    logEvent("EmailResent", evtData);
                    ShowSnackbarElement("Send Email Completed", "success");
                    setSendInProgress(false);
                })
                .catch((error) => {
                    ShowSnackbarElement("Send Email Failed", "error");
                    setSendInProgress(false);
                });
        } else {
            reSendNotification(data?.notificationId!)
                .unwrap()
                .then(() => {
                    let rcpnt: string = "Unknown";
                    if (data?.recipients && data?.recipients.length > 0) {
                        rcpnt =
                            data.recipients.length > 1
                                ? data.recipients.map((r) => r.identifier).join(", ")
                                : data.recipients[0].identifier;
                    }
                    const evtData: AccessEventNotificationDetails = {
                        notificationId: data?.notificationId ?? "",
                        subject: data?.subject ?? "",
                        recipient: rcpnt,
                    };

                    logEvent("EmailResent", evtData);
                    ShowSnackbarElement("Resend Email Completed", "success");
                    setSendInProgress(false);
                })
                .catch((error) => {
                    ShowSnackbarElement("Resend Email Failed", "error");
                    setSendInProgress(false);
                });
        }
    };

    let filterdata = {
        notificationId: data?.notificationId,
        subject: data?.subject,
        createdUtc: data?.createdUtc,
        sender:
            data?.sender.name !== "" && data?.sender.name !== null
                ? '"' + data?.sender.name + '"<' + data?.sender.identifier + ">"
                : data?.sender.identifier,
        replyTo:
            data?.replyTo.name !== "" && data?.replyTo.name !== null
                ? '"' + data?.replyTo.name + '"<' + data?.replyTo.identifier + ">"
                : data?.replyTo.identifier,
        ccRecipients: GetDtoValue("Cc"),
        bccRecipients: GetDtoValue("Bcc"),
        recipients: GetDtoValue("To"),
        templateTrackingId: data?.templateTrackingId,
    };

    const ShowSnackbarElement = (message: string, severity: AlertColor) => {
        setShowSnackbar(true);
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
    };

    function GetDtoValue(ToValue: string | undefined) {
        let toValue: string = "";
        switch (ToValue) {
            case "To":
                data?.recipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "") +
                              (items.alternateIdentifier !== null
                                  ? "<" + items.alternateIdentifier + "> "
                                  : "")
                            : items.identifier +
                              " " +
                              (items.alternateIdentifier != null ? items.alternateIdentifier : ""));
                });
                return toValue;
            case "Cc":
                data?.ccRecipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "")
                            : items.identifier);
                });
                return toValue;
            case "Bcc":
                data?.bccRecipients.forEach((items, i) => {
                    toValue =
                        toValue +
                        (items.name.trim() !== "" && items.name !== null
                            ? '"' +
                              items.name +
                              '" ' +
                              (items.identifier !== null ? "<" + items.identifier + "> " : "")
                            : items.identifier);
                });
                return toValue;
        }
    }

    if (isLoading) {
        return <ComponentLoader msg={"Loading"} />;
    }

    if (isSuccess) {
        return (
            <Grid container>
                <Grid item xs={12} lg={5}>
                    {showSnackbar ? (
                        <CclAlertSnackbar
                            open={true}
                            onClose={() => setShowSnackbar(false)}
                            message={snackbarMessage}
                            severity={snackbarSeverity}
                        />
                    ) : null}
                    <Grid container>
                        <Grid item xs={12} margin={1}>
                            <CclGenericAccordian headingText="General">
                                <Box
                                    sx={{
                                        m: 2,
                                        border: 1,
                                        borderColor: "#EEEEEE",
                                        borderRadius: 3,
                                    }}
                                >
                                    <CclInfoTable
                                        template={CclInfoTblNotificationTemplate}
                                        data={filterdata}
                                    />
                                    <CardActions sx={{ float: "right" }}>
                                        <ReadOnlyGate
                                            readOnly={isUserClientAdmin}
                                            reactElement={
                                                <CclLoadingButton
                                                    onClick={ResendNotification}
                                                    loading={sendInProgress}
                                                    restricted={false}
                                                    mode={"primary"}
                                                >
                                                    {features?.resend?.active
                                                        ? "Send Email"
                                                        : "Resend "}
                                                </CclLoadingButton>
                                            }
                                        />
                                    </CardActions>
                                </Box>
                            </CclGenericAccordian>
                        </Grid>
                        <Grid item xs={12} margin={1}>
                            <CclGenericAccordian headingText="History">
                                <Box
                                    sx={{
                                        h: 1,
                                        m: 1,
                                        border: 1,
                                        borderColor: "#EEEEEE",
                                        borderRadius: 3,
                                    }}
                                >
                                    <div style={{ overflow: "auto" }}>
                                        {data?.statuses.map((items, i) => (
                                            <Box sx={{ p: 2 }}>
                                                <Typography variant="body2" gutterBottom>
                                                    <b>{items.name}: </b>{" "}
                                                    {new Date(items.createdUtc).toLocaleString()}{" "}
                                                    <br /> {items.description}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </div>
                                </Box>
                            </CclGenericAccordian>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={8} lg={6} margin={1}>
                    <NotificationPreviewPanel
                        notificationId={props.notificationId}
                        forceChecked={false}
                    />
                </Grid>
            </Grid>
        );
    }
    return <span>Failed to Load Notification Information</span>;
};

export default NotificationGeneralPanel;
